img {
  max-width: 100%;
  height: auto;
}

body {
  font-family: 'Montserrat', sans-serif;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;

  & > svg {
    display: none;
  }
}

* {
  box-sizing: border-box;
  font-family: inherit;
}

.page:not(.home), .single-services, .error404 {
  .header, .footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 200;

    @media only screen and (max-width: 980px) {
      position: relative;
    }
  }

  .header {
    top: 0;
  }

  .footer {
    bottom: 0;
  }
}

#mobile-toggle {
  display: none;

  &:checked {
    & ~ .nav__main {
      opacity: 1;
      transform: translateX(0);
    }

    & ~ .mobile-toggle {
      background: $green;
    }
  }
}

.mobile-toggle {
  position: absolute;
  right: 70px;
  top: 50%;
  margin-top: -30px;
  padding: 15px;
  display: none;
  transition: 300ms;

  svg {
    width: 30px;
    height: 30px;
  }

  @media only screen and (max-width: 980px) {
    display: block;
  }
}

.header {
  position: relative;
  z-index: 2000;
  background: white;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include center;
}

.logo {
  margin: 0;
  text-align: center;

  a {
    display: block;
  }

  svg {
    width: 185px;
    height: 60px;
    vertical-align: middle;
  }

  @media only screen and (max-width: 980px) {
    svg {
      padding: 10px 0;
    }
  }
}

.nav {
  display: flex;
  padding-right: 60px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    white-space: nowrap;
  }

  li {
    display: block;
    position: relative;

    &.menu-item-has-children {
      padding-right: 10px;

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -2px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: $green transparent transparent transparent;
      }
    }

    &:hover {
      ul {
        opacity: 1;
        visibility: visible;
      }
    }

    ul {
      position: absolute;
      background: $green;
      display: block;
      width: 300px;
      text-align: center;
      left: 50%;
      margin-left: -150px;
      opacity: 0;
      visibility: hidden;
      transition: 300ms;
      width: 500px;
      margin-left: -250px;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background: #1f7361;
        transform: rotate(45deg);
        top: -10px;
        left: 50%;
        margin-left: -10px;
      }

      a {
        text-align: left;
        padding: 12px 30px;
        color: white;
        border-bottom: 1px solid #59a796;
        color: #EAEAEA;
        background: #1f7361;
        white-space: normal;
        line-height: 1.4;
        transition: 200ms;

        &:hover {
          background: $green;
          color: white;
        }
      }
    }
  }

  a {
    color: black;
    text-decoration: none;
    font-weight: bold;
    padding: 0 2px 0 8px;
    line-height: 100px;
    display: block;
    font-size: 12px;
    color: $grey;
    font-weight: 500;
    text-transform: uppercase;

    &:hover {
      color: $green
    }
  }
}

@media only screen and (max-width: 980px) {
  .nav__main {
    position: fixed;
    background: $green;
    width: 300px;
    top: 60px;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    transition: 300ms;
    z-index: 2000;

    ul {
      display: block;
    }

    li {
      display: block;
      padding: 0 !important;

      &.menu-item-has-children:after {
        top: 25px;
        right: 20px;
        border-color: white transparent transparent transparent;
      }

      &:hover {
        ul {
          max-height: 1000px;
        }
      }

      ul {
        overflow: hidden;
        max-height: 0;
        position: static;
        width: auto;
        margin: 0;

        &:before {
          display: none;
        }
      }

      li {
        display: block;
      }
    }

    a {
      padding: 20px 40px;
      line-height: 1;
      color: white;
      border-bottom: 1px solid lighten($green, 10%);

      &:hover {
        color: white;
        background: darken($green, 10%);
      }
    }
  }
}

.nav__lang {
  display: flex;
  margin-left: 40px;
  flex-direction: column;
  position: absolute;
  right: 20px;
  background: darken($green, 12%);
  border-radius: 50px;
  top: 50%;
  padding: 0;
  margin: 0;
  margin-top: -20px !important;
  width: 40px;
  max-height: 40px;
  overflow: hidden;
  transition: 500ms;
  display: flex !important;
  justify-content: flex-start !important;

  li {
    order: 1;
  }

  .is-active {
    border-radius: 50%;
    background: $green;
    order: 0;
    pointer-events: none;
  }

  &:hover {
    max-height: 300px;
  }

  a {
    padding: 0 10px;
    color: white;
    line-height: 40px;
    text-align: center;
    transition: 200ms;

    &:hover {
      color: inherit;
    }
  }
}

.hero {
  background-size: cover;
  background-position: center;
  position: relative;

  &:after, &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(../images/overlay_image.svg) no-repeat bottom right;
    height: 100%;
    width: 100%;
    z-index: 20;
  }

  &:after {
    mix-blend-mode: multiply;
    background: rgba($green, 0.9);
    z-index: 10;
  }

  @media only screen and (max-width: $max-width) {
    &:before {
      background-size: 30% auto;
    }
  }
}

.hero__inner {
  @include center;
  position: relative;
  z-index: 30;
}

.hero__column {
  max-width: 660px;
  padding: 30% 0;
  max-height: 100vh;
}

.hero__title {
  font-size: rem(40px);
  font-weight: 400;
  line-height: 1.1;
  color: white;
  letter-spacing: 0.1em;
  margin-bottom: 20px;

  @media only screen and (max-width: 600px) {
    text-align: center;
    font-size: rem(32px);
  }
}

.hero__subtitle {
  font-size: rem(20px);
  font-weight: 400;
  line-height: 1.47;
  color: white;
  letter-spacing: 0.1em;
  margin: 0;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
}

.section {
  padding: 4% 0;
}

.section--news {
  background: radial-gradient(409.00px at 50% 50%, #FFFFFF 0%, #EAE6E6 100%);
}

.section__inner {
  @include center;
}

  .section__title {
    font-size: rem(20px);
    margin: 40px 0 80px 0;
    font-weight: 600;
    color: $green;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1em;

    &:before, &:after {
      content: "";
      display: inline-block;
      background: #000;
      vertical-align: middle;
      margin: 0 40px;
      width: 160px;
      height: 1px;
    }

    @media only screen and (max-width: 800px) {
      &:before, &:after {
        display: none;
      }
    }

    @media only screen and (max-width: 600px) {
      margin: 30px 0 50px 0;
    }
  }

.services {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
}

.service__item {
  width: 1/4 * 100%;

  img {
    max-width: none;
    width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    display: block;
    transition: 200ms;

    &:hover {
      color: $green;
    }
  }

  @media only screen and (max-width: 980px) {
    width: 1/2 * 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%
  }
}

.service__item-title {
  padding-left: 40px;
  padding-right: 40px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: rem(16px);
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.service__item-content {
  letter-spacing: 0.1em;
  font-size: rem(14px);
  padding-top: 10px;
  padding-right: 60px;
  padding-bottom: 30px;
  padding-left: 40px;

  .service__item:nth-child(2n) & {
    background: darken(white, 2%);
  }
}

.news-page {
  @include center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: stretch;
}

.news-slider {
  @include center;
  font-size: 0;
  padding-bottom: 120px;

  .flickity-page-dots {
    bottom: 20px;
    white-space: nowrap;

    .dot {
      transition: 200ms;
      margin: 0 20px;
      background: $green;
    }

    .is-selected {
      transform: scale(1.5);
    }

    @media only screen and (max-width: 600px) {
      .dot {
        margin: 0 10px;
      }
    }
  }
}

.news__item {
  font-size: 16px;
  width: 380px / $max-width * 100%;
  margin-right: 20px;
  vertical-align: top;
  margin-bottom: 20px;
  display: inline-flex;
  flex-direction: column;
  min-height: 100%;

  &:nth-child(3n) {
    margin-right: 0;
  }

  .flickity-enabled & {
    margin-right: 20px !important;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  a {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    transition: color 200ms;

    &:hover {
      color: $green;

      .news__item-content {
        color: $green;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    width: 570px / $max-width * 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%
  }
}

.pagination {
  text-align: center;
  padding: 40px 0 0;

  a {
    color: $green;
    padding: 20px;
    text-decoration: none;

    &:hover {
      color: #333;
    }
  }
}

.news__item--grid {
  width: auto;
  margin: 0;
}

.news__item-wrapper {
  background: linear-gradient(180deg, #3F796C 0%, #5DAE9C 61.76%);
  padding: 20px 20px 40px;
  flex: 1;
}

.news__item-title {
  font-size: rem(20px);
  font-weight: 600;
  font-weight: 300;
  margin: 20px 0 40px 0;
  color: white;
}

.news__item-date {
  font-size: rem(14px);
  color: white;
  text-transform: uppercase;
  margin: 0;
}

.news__item-more {
  color: white;
  font-style: italic;
  margin: 0;

  &:before {
    content: "";
    display: inline-block;
    height: 1px;
    width: 20px;
    vertical-align: middle;
    background: white;
    margin: 0 10px 0 0;
  }
}

.newsletter {
  background: $green url(../images/nl_bg.jpg) no-repeat center top;
  background-size: cover;
  overflow: hidden;
  padding: 2% 0 4% 0;

  img {
    width: 80px;
    height: auto;
    margin: 0 auto 40px auto;
    display: block;
  }
}

.newsletter__form {
  display: flex;
  padding: 20px 40px 30px;
  max-width: 80%;
  margin: 0 auto;
  align-items: center;

  form {
    display: flex;
    align-items: center;
    flex: 1;
  }

  h3 {
    text-transform: uppercase;
    font-size: rem(20px);
    letter-spacing: 0.2em;
    font-weight: 300;
    margin: 0 40px 0 0;
  }

  input[type="text"], input[type="email"] {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border: 0;
    padding: 10px;
    flex: 1;
    font-size: rem(14px);
    font-style: italic;
  }

  input[type="submit"] {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 0;
    padding: 10px 30px;
    background: $green;
    text-transform: uppercase;
    color: white;
    font-size: rem(14px);
    letter-spacing: 0.1em;
  }

  @media only screen and (max-width: 800px) {
    h3 {
      display: none;
    }
  }
}

.newsletter__form-gdpr {
  padding-left: 10px;
  border-left: 1px solid white;
  margin: 0 0 0 20px;
  font-size: rem(12px);
  position: relative;
  padding: 20px 0 20px 20px;

  svg {
    width: 22px;
    height: 22px;
    vertical-align: middle;
    margin-right: 10px;
  }

  &:hover {
    div {
      opacity: 1;
      visibility: visible;
    }
  }

  div {
    transition: 300ms;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 300px;
    background: #333;
    color: white;
    padding: 12px;

    p {
      margin: 0;
    }
  }

  @media only screen and (max-width: 980px) {
    border: 0;
    margin: 0;

    div {
      right: 50%;
      margin-right: -150px;
    }
  }
}

.newsletter__form--footer {
  margin: 0;
  padding: 20px 0;
  width: 100%;
  max-width: 800px;

  @media only screen and (max-width: 980px) {
    display: block;
    padding-top: 10px;
    padding-bottom: 0;
  }
}

.newsletter__inner {
  max-width: 780px;
  margin: 40px auto;
  color: white;
}

.newsletter__title {
  font-size: rem(20px);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 300;
  text-align: center;
}

.newsletter__subtitle {
  font-weight: 300;
  text-align: center;
  font-size: rem(16px);
  letter-spacing: 0.1em;
}

.newsletter__disclaimer {
  p {
    font-size: rem(10px);
    text-align: center;
  }
}

.footer {
  padding: 40px 0 0;
  background: $green;
  color: white;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(../images/logo_washed.svg) no-repeat right top;
    background-size: auto 100%;
    z-index: 10;
    pointer-events: none;
  }
}

.footer--dark {
  background: #3F796C;
  padding: 0;
}

.footer__short {
  display: flex;
  justify-content: space-between;
  padding: 9px 20px;
  line-height: 1;

  h2 {
    font-size: rem(14px);
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    vertical-align: middle;
    margin-right: 20px;
  }

  @media only screen and (max-width: 980px) {
    display: block;
    text-align: center;

    h2 {
      display: block;
    }
  }
}

.footer__copyright {
  background: white;
  color: $green;
  text-align: center;
  margin: 0;
  padding: 20px;
  position: relative;
  z-index: 20;
}

.footer__inner {
  @include center;
  display: flex;
  padding: 60px 0 100px 0;
  position: relative;
  z-index: 30;

  @media only screen and (max-width: 600px) {
    display: block;
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.footer__info {
  max-width: 320px;
  margin-right: 10%;
  padding-left: 100px;

  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  h2 {
    margin-left: -100px;
    padding-left: 100px;
    position: relative;
    font-weight: 400;
    text-transform: uppercase;
    font-size: rem(20px);
    letter-spacing: 0.1em;
    margin-bottom: 40px;
  }

  h3, p {
    padding-left: 30px;
    position: relative;
  }

  h3 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: rem(15px);
  }

  p {
    font-size: rem(15px);
    line-height: 1.5;
  }

  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 2px;
  }

  @media only screen and (max-width: 600px) {
    margin: 0 auto;
  }
}

.footer__contact {
  max-width: 400px;
  width: 100%;

  input[type="text"], input[type="email"], textarea {
    padding: 10px;
    color: white;
    border: 1px solid white;
    margin-bottom: 5px;
    background: transparent;
    width: 100%;
    display: block;
    font-size: rem(15px);

    &::placeholder {
      color: white;
    }
  }

  textarea {
    margin-bottom: 20px;
  }

  input[type="submit"], button {
    display: block;
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: rem(16px);
    color: white;
    border: 0;
    background: transparent;
  }

  @media only screen and (max-width: 600px) {
    margin: 40px auto 0 auto;
  }
}

.page-wrapper {
  position: relative;
  padding: 100px 0 120px 50%;

  @media only screen and (max-width: 980px) {
    padding: 0 !important;
  }
}

.page-wrapper--right {
  padding-left: 0;
  padding-right: 50%;
}

.page__header {
  background: $green url(../images/logo_washed.svg) no-repeat right 50% top;
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  padding-top: 100px;
  padding-bottom: 120px;
  overflow: hidden;

  img {
    position: absolute;
    mix-blend-mode: multiply;
    top: 0;
    left: 0;
    height: 100vh;
    width: auto;
    z-index: 0;
    object-fit: cover;
    max-width: none;
    opacity: 0.7;
  }

  @media only screen and (max-width: 980px) {
    position: relative;
    padding: 50px 0;
    left: 0 !important;
    right: 0 !important;
    align-items: center !important;
  }
}

.page__header--flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}

.page__header--left {
  left: 50%;
  right: 0;
}

.page__title {
  color: white;
  font-weight: 300;
  font-size: rem(38px);
  text-align: right;
  width: 100%;
  margin: 0;
  padding: 60px 80px 30px 80px;
  margin: 0;
  position: relative;
  z-index: 10;
  word-break: break-all;

  @media only screen and (max-width: 980px) {
    text-align: center;
  }
}

.page__button {
  text-transform: uppercase;
  color: $green;
  padding: 15px 60px;
  background: white;
  text-align: center;
  margin-right: 80px;
  text-decoration: none;
  transition: 200ms;

  &:hover {
    background: #CD1919;
    color: white;
  }
}

.page__title--left {
  text-align: left;
}

.page__title--bold {
  font-weight: 600;
  text-transform: uppercase;
  max-width: 80%;
  font-size: rem(42px)
}

.page-content {
  padding: 5% 0;
  display: flex;
  align-items: flex-start;
  min-height: calc(100vh - 220px);

  @media only screen and (max-width: 980px) {
    min-height: 0;
  }
}

.page-content--grey {
  background-color: darken(white, 2%);
}

.page-content--image {
  background-image: url(../images/overlay_image.svg) no-repeat bottom right;
}

.page-content__inner {
  @include center;
  max-width: 770px;
  font-size: 16px;
  line-height: 1.78;

  h1, h2, h3, h4, h5, h6 {
    color: $green;
  }

  h2 {
    font-size: rem(24px);
    line-height: 1.5;
    letter-spacing: normal;
    font-weight: 400;
    margin: 40px 0;
  }

  a {
    color: #ED2830;

    &:hover {
      color: $green;
    }
  }

  li {
    margin-bottom: 20px;
  }

  ul {
    li {
      &:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background: $green;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 6px;
      }

      display: block;
      padding-left: 40px;
      position: relative;
    }
  }

  .wp-caption-text {
    font-size: rem(14px);
    color: #aaaaaa;
    margin-top: 0;
    margin-bottom: 50px;
  }
}

.page-content__inner--left {
  margin-left: 40px;

  @media only screen and (max-width: 980px) {
    margin-left: 0;
  }
}

.post__header {
  overflow: hidden;
  position: relative;
  background: $green;

  img {
    object-fit: cover;
    position: absolute;
    z-index: 10;
    height: 100%;
    opacity: 0.3;
    mix-blend-mode: multiply;
  }
}

.post__header-wrapper {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 20;
  padding: 10% 20px;
}

.post__title {
  font-size: rem(40px);
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  color: white;
}

.post__header-meta {
  display: flex;
}

.post__date {
  font-size: rem(16px);
  color: white;
  margin: 0;
}

.post__category {
  display: flex;
  align-items: center;
  margin-right: 20px;

  &:after {
    content: "";
    height: 2px;
    width: 50px;
    display: block;
    margin-left: 20px;
    background: #CD1919;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
  }

  a {
    color: #CD1919;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    font-size: rem(16px);
  }
}

.post__wrapper {
  @include center;
  background: white;
  position: relative;
  z-index: 10;
}

.post__back {
  position: absolute;
  top: -40px;
  left: 5px;
  color: white;
  text-decoration: none;
  display: block;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    transition: 200ms;
  }

  &:hover {
    svg {
      transform: translateX(-5px);
    }
  }
}

.share-links {
  @include center;
  padding: 0;
  flex: 1;
  margin-right: 40px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  li {
    display: block;
  }

  a {
    display: block;
    padding-left: 10px;
  }

  svg {
    width: 38px;
    height: 38px;
    fill: $green;
  }
}

.share-links__title {
  font-size: rem(14px);
  font-weight: 500;
  margin: 20px 0 20px 0;
  text-transform: uppercase;
  text-align: right;
}

.post-related {
  @include center;
  margin: 50px auto 5% auto;
  max-width: 790px;
}

.post-related__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  padding-top: 40px;
  border-top: 1px solid darken(white, 10%);
}