@function rem($size) {
  $rem: $size / 16px;
  @return #{$rem}rem;
}

@mixin center () {
  max-width: $max-width;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: content-box;
}